export default {
    name: "Ethereum Classic",
    coin: "ETC",
    scheme: "SOLO",
    account: '0x2b2c7660a665fe35feaa47317a8bd95f543c188d',
    explorer_url: 'https://www.oklink.com/etc',
    coingecko: 'https://www.coingecko.com/en/coins/ethereum-classic',
    explorer: {
        address: "https://www.oklink.com/etc/address/",
        height: "https://www.oklink.com/etc/block/",
        hash: "https://www.oklink.com/etc/block/",
        tx: "https://www.oklink.com/etc/tx/",
    },
    ports: [
        {
            info: "LOW",
            host: "molepool.com",
            port: 4444,
            diff: 2,
        },
        {
            info: "MID",
            host: "molepool.com",
            port: 4422,
            diff: 8,
        },
        {
            info: "HIGH",
            host: "molepool.com",
            port: 4440,
            diff: 16,
        }
    ]
};